.even-row{
    background-color: rgb(233, 231, 231);
}

  /* LoginPage.css */
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .login-div {
    padding: 100px 50px;
    text-align: center;
    /* background: #d7d7d7; */
    border-radius: 5px;
  }
  
  .login-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .google-button {
    background-color: #4285f4;
    color: #fff;
  }
  
  .msft-button {
    background-color: #333;
    color: #fff;
  }
  
  .git-button {
    background-color: #fff;
    color: #333;
  }